import React, { useState } from "react"
import styled from "styled-components"
import MenuItems from "./MenuItems"
import Parser from "html-react-parser"

// import material ui
import Typography from "@material-ui/core/Typography"

// import app components
import Link from "components/link"

import * as theme from "theme"
import { useStore } from "store"
import { getUrlPath } from "utils/getUrlPath"

const Sidebar = (props) => {
  const { slug } = props

  const [
    {
      appState: { activeSection }
    }
  ] = useStore()

  // We don't have access to the items yet, so the default active state is null
  const [activeItem, setActiveItem] = useState(null)
  const [subMenuVisibility, setSubMenuVisibility] = useState(true)

  const onClickSubItem = (ID) => {
    setActiveItem(ID)

    // Close menu if already open and open if closed
    if (activeItem === ID && subMenuVisibility === true) {
      setSubMenuVisibility(false)
    } else {
      setSubMenuVisibility(true)
    }
  }

  const urlSection = slug.split("/")[1]

  return (
    <Navigation {...props}>
      <MenuItems
        name={urlSection.includes("architects") ? ["architects"] : urlSection.includes("about") ? ["about"] : ["main"]}
      >
        {(items) => {
          // Find sub nav menu by active section
          let item = items.find((o) => getUrlPath(o.url).includes(activeSection || urlSection))

          // If no section is provided (Why western red cedar) then use siding as default section
          if (!item && items.length > 0) {
            item = items[0]
          }

          let productItems = items.find((o) => o.cssClasses.includes("products"))

          const prdItem = productItems?.childItems?.nodes.find((onavItem) =>
            onavItem?.label?.toLowerCase().includes(activeSection)
          )

          if (prdItem) {
            item = prdItem
          }

          // Recursive loop through children to find sub section, i.e. introduction by exact slug
          const activeSubSection = item?.childItems?.nodes
            ? item.childItems.nodes.find((o) => o?.childItems.nodes.find((p) => slug === getUrlPath(p.url, false))) ||
              item.childItems.nodes.find((o) => !o.cssClasses.includes("section-title"))
            : false

          return (
            <>
              {item ? (
                <div key={item.id}>
                  <Typography component="div">
                    <StyledLink to={getUrlPath(item.url)}>{item.label}</StyledLink>
                  </Typography>

                  {!!item.childItems && (
                    <SubMenu active={item.id === activeItem ? "true" : "false"}>
                      <div>
                        {item.childItems.nodes
                          .filter((o) => !o.cssClasses.includes("section-title"))
                          .map((subItem) => {
                            // Get active menu item. Since we don't have the active state on page load (no access to menu items)
                            // we have to make an additional check. First check state, if null then use active sub section
                            const isSectionActive = activeItem
                              ? subItem.id === activeItem
                              : activeSubSection && activeSubSection.id === subItem.id

                            return (
                              <SubItem key={subItem.id}>
                                {subItem?.childItems?.nodes?.length ? (
                                  <SubItemHeadline
                                    variant="caption"
                                    children={Parser(subItem.label)}
                                    active={isSectionActive}
                                    onClick={() => onClickSubItem(subItem.id)}
                                    action={true}
                                  />
                                ) : (
                                  <Typography component="div">
                                    <SubSubItem
                                      to={getUrlPath(subItem.url)}
                                      children={Parser(subItem.label)}
                                      isActive={getUrlPath(subItem.url) === slug}
                                    />
                                  </Typography>
                                )}
                                {subMenuVisibility && subItem.childItems && (
                                  <SubSubMenu active={isSectionActive}>
                                    {subItem.childItems.nodes.map((subSubItem) => {
                                      return (
                                        <Typography key={subSubItem.id} component="div">
                                          {subSubItem.cssClasses.includes("nav-title") ? (
                                            <SubItemHeadline
                                              variant="caption"
                                              children={Parser(subSubItem.label)}
                                              action={false}
                                            />
                                          ) : (
                                            <SubSubItem
                                              to={getUrlPath(subSubItem.url)}
                                              children={Parser(subSubItem.label)}
                                              isActive={getUrlPath(subSubItem.url) === slug}
                                            />
                                          )}
                                        </Typography>
                                      )
                                    })}
                                  </SubSubMenu>
                                )}
                              </SubItem>
                            )
                          })}
                      </div>
                    </SubMenu>
                  )}
                </div>
              ) : (
                <div />
              )}
            </>
          )
        }}
      </MenuItems>
    </Navigation>
  )
}

export default Sidebar

const Navigation = styled.nav`
  overflow: hidden;
`

const StyledLink = styled(Link)`
  display: block;
  padding: 15px 20px 10px;
  font-size: 24px;
  font-weight: 400;
  text-decoration: none;
  color: ${theme.colors.primary};
  text-transform: uppercase;
`

const SubMenu = styled.div`
  width: 100%;
`

const SubItem = styled.div`
  span {
    padding: 10px 20px;
  }
`

const SubItemHeadline = styled(({ action, active, ...rest }) => <Typography {...rest} />)`
  position: relative;
  display: block;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  color: ${theme.colors.primary};
  text-transform: uppercase;
  cursor: ${(props) => (!props.action ? "default" : "pointer")};
  background: ${(props) => (props.active ? "#fff" : "transparent")};

  &:before {
    content: "+";
    position: absolute;
    z-index: 10px;
    right: 10px;
    top: 5px;
    opacity: ${(props) => (props.active ? 0 : 1)};
    font-size: 16px;
    font-weight: 400;
    display: ${(props) => (!props.action ? "none" : "block")};
  }

  &:after {
    content: "-";
    position: absolute;
    z-index: 10px;
    right: 10px;
    top: 5px;
    opacity: ${(props) => (props.active ? 1 : 0)};
    font-size: 16px;
    font-weight: 400;
    display: ${(props) => (!props.action ? "none" : "block")};
  }
`

const SubSubMenu = styled(({ active, ...rest }) => <div {...rest} />)`
  height: ${(props) => (props.active ? "auto" : 0)};
  overflow: hidden;
  background: ${theme.colors.lightmediumgrey};

  .active-item {
    color: #fff;
    background: ${theme.colors.primary};
  }
`

const SubSubItem = styled(({ isActive, ...rest }) => <Link {...rest} />)`
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: ${theme.colors.primary};
  font-size: 14px;

  ${(props) =>
    props.isActive &&
    `
    color: #fff;
    background: ${theme.colors.primary};
  `}

  &:hover {
    color: #fff;
    background: ${theme.colors.primary};
  }
`
