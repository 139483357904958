import React from "react"
import styled, { css } from "styled-components"
import Typography from "@material-ui/core/Typography"

import Link from "components/link"

import * as theme from "theme"

export default function Breadcrumbs(props) {
  const decodedPath = decodeURIComponent(props.pathname)

  const paths = decodedPath.replace(/\//g, " ").trim().split(" ")

  return (
    <Container>
      <Typography component="div">
        <Wrapper>
          <StyledLink to="/" children="Home" />

          {!!paths &&
            paths.map((item, index) => {
              return (
                <StyledLink
                  key={index}
                  to={"/" + paths.slice(0, index + 1).join("/")}
                  activeitem={index === paths.length - 1 ? "true" : 0}
                  children={item}
                  wordLength={item?.length}
                />
              )
            })}
        </Wrapper>
      </Typography>
    </Container>
  )
}

const Container = styled.div`
  display: block;
  overflow: hidden;

  /* @media (min-width: 640px) {
    display: inline-block;
    overflow: hidden;
  } */
`
const Wrapper = styled.div`
  background: ${theme.colors.lightgrey};
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  line-height: 1em !important;
  margin: 0 0 0.5rem;
  position: relative;
  width: fit-content;
  height: fit-content;
  @media (min-width: 640px) {
    margin: 0 0 1rem;
  }
`

const StyledLink = styled(Link)`
  padding: 4px 8px 0;
  color: inherit;
  text-decoration: none;
  background: ${theme.colors.lightgrey};
  text-transform: uppercase;
  font-size: 10px;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  max-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  &:before {
    content: "...";
    width: 30px;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 1px;
    z-index: 1;
    background: inherit;
    align-items: flex-end;
    padding-left: 2px;
    display: ${(props) => (props.wordLength > 10 ? "flex" : "none")};
    @media (min-width: 640px) {
      display: none;
    }
  }
  @media (min-width: 640px) {
    font-size: 12px;
    max-width: unset;
  }
  &:hover {
    background: ${theme.colors.primary};
    color: ${theme.colors.lightgrey};
  }

  ${(props) =>
    props.activeitem === "true" &&
    css`
      background: ${theme.colors.primary};
      color: ${theme.colors.lightgrey};
    `}
`
