import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Parser from "html-react-parser"
import { isMobile } from "react-device-detect"

// import material ui
import Typography from "@material-ui/core/Typography"

// import app components
import BackgroundImage from "components/backgroundImage"
import Edges from "components/edges"
import Breadcrumbs from "components/breadcrumbs"
import * as theme from "theme"

export default function Hero(props) {
  const { image, title, youtubeVideoId, breadcrumbs, ...rest } = props

  const { defaultHeroImage } = usePlaceholderQuery()

  const videoSrc = `https://www.youtube.com/embed/${youtubeVideoId}?autoplay=1&mute=1&controls=0&loop=1&playlist=${youtubeVideoId}`

  return (
    <>
      {youtubeVideoId && !isMobile && (
        <VideoContainer {...rest}>
          <div className="relative w-full h-full z-30 overflow-hidden">
            <iframe
              className="absolute top-1/2 left-0 -translate-y-1/2 w-full min-h-full"
              style={{ minHeight: "56.25vw" }}
              src={videoSrc}
              allow="autoplay; fullscreen"
              allowFullScreen
              title="Background Video"
            ></iframe>
            <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center">
              <VideoContent>
                <Edges size="lg" className="z-30">
                  {!!breadcrumbs && <Breadcrumbs pathname={breadcrumbs} />}
                  {!!title && <Headline variant="h2" component="h1" children={Parser(title)} />}
                </Edges>
              </VideoContent>
            </div>
          </div>
        </VideoContainer>
      )}
      {(!youtubeVideoId || isMobile) && (
        <Container {...rest}>
          <Content>
            <Edges size="lg">
              {!!breadcrumbs && <Breadcrumbs pathname={breadcrumbs} />}
              {!!title && <Headline variant="h2" component="h1" children={Parser(title)} />}
            </Edges>
          </Content>

          <BackgroundImage image={image || defaultHeroImage} />
        </Container>
      )}
    </>
  )
}

const VideoContainer = styled.div`
  position: relative;
  height: 400px;
  overflow: hidden;
  background-color: ${theme.colors.lightgrey};
`

const VideoContent = styled.div`
  position: absolute;
  z-index: 20;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: 20px;

  &:after {
    content: "";
    position: absolute;
    z-index: 10;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 200px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%);
  }
`

const Container = styled.div`
  position: relative;
  height: auto;
  min-height: 200px;
  overflow: hidden;
  background-color: ${theme.colors.lightgrey};

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    height: 250px;
  }

  &:after {
    content: "";
    position: absolute;
    z-index: 10;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 200px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%);
  }
`

const Content = styled.div`
  position: absolute;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: 20px;
`

const Headline = styled(Typography)`
  && {
    color: #fff;
  }
`
const usePlaceholderQuery = () => {
  const {
    wp: {
      themeSettings: { siteOptions }
    }
  } = useStaticQuery(
    graphql`
      {
        wp {
          themeSettings {
            siteOptions {
              defaultHeroImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return siteOptions
}
