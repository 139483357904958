import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import ReactGA from "react-ga"
import { Helmet } from "react-helmet"

// import material ui
import { Typography } from "@material-ui/core"

// import app components
import Textarea from "components/textarea"
import Spacer from "components/spacer"
import SidebarContent from "components/sidebarContent"
import NewsletterModule from "components/flexibleContent/NewsletterModule"

import PdfIcon from "../../../../static/images/icons/pdf.svg"
import SketchupIcon from "../../../../static/images/icons/sketchup.svg"
import PlayIcon from "../../../../static/images/icons/play-icon.svg"
import { handleTracking } from "../../../utils/handleTracking"

import { getUrlPath } from "../../../utils/getUrlPath"
import * as theme from "theme"
import ProjectMaterials from "../../../components/project/project-materials"

export default function Project(props) {
  const {
    data: {
      wpProject: {
        databaseId,
        title,
        uri,
        date,
        Project: { youtubeUrl, text, image, resourceLinks, materials }
      }
    }
  } = props

  const [newsletterCta, setNewsletterCta] = useState(false)

  const { GATSBY_SITE_URL } = process.env

  const videoId =
    !!youtubeUrl && (youtubeUrl.includes("youtu.") || youtubeUrl.includes("/embed"))
      ? youtubeUrl.substring(youtubeUrl.lastIndexOf("/") + 1)
      : !!youtubeUrl && youtubeUrl.includes("youtube")
      ? youtubeUrl.substring(youtubeUrl.lastIndexOf("=") + 1)
      : null

  const videoObj = {
    "@context": "https://schema.org/",
    "@type": "VideoObject",
    name: title,
    description: text,
    thumbnailUrl: image?.localFile?.publicURL ? GATSBY_SITE_URL + image.localFile.publicURL : "",
    uploadDate: date || "",
    contentUrl: youtubeUrl
  }

  useEffect(() => {
    const trackingId = "UA-40595314-2"
    ReactGA.initialize(trackingId)
  }, [])

  // create new array with all pdf files
  const pdfFiles = resourceLinks.map((resource) => resource.resourceType === "file" && { ...resource }).filter(Boolean)

  // create new array with all sketchup files
  const sketchFiles = resourceLinks
    .map((resource) => (resource.resourceType === "sketchup" || resource.resourceType === "link") && { ...resource })
    .filter(Boolean)

  const handleFileDownload = (args) => {
    const { label, resourceType } = args
    process.env.GATSBY_ACTIVE_LANGUAGE === "EN" && handleTracking("Lead", uri)

    if (resourceType === "file" || resourceType === "sketchup") {
      ReactGA.event({
        category: "PDF & Sketchup Downloads",
        action: "Downloaded",
        label: label
      })

      setNewsletterCta(true)
    }
  }

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(videoObj)}</script>
      </Helmet>
      <SidebarContent image={image} title={title} slug={getUrlPath(uri)} hideSidebarOnMobile>
        <Spacer pb={40}>
          <Attachments>
            {!!pdfFiles.length && (
              <>
                <Headline gutterBottom>PDF Instructions:</Headline>
                {pdfFiles.map(({ resourceFile, resourceName, resourceLink, resourceType }, index) => {
                  const label = resourceFile?.localFile?.publicURL || resourceLink

                  return (
                    <File
                      key={index}
                      href={!!resourceFile ? resourceFile.localFile?.publicURL : resourceLink}
                      onClick={() =>
                        handleFileDownload({
                          label,
                          resourceType
                        })
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`pdf-download`}
                    >
                      <PdfIcon />
                      <ResourceName color="inherit">{resourceName}</ResourceName>
                    </File>
                  )
                })}

                <File style={{ minHeight: "auto", margin: 0, padding: 0, height: 0, border: "none" }} />
                <File style={{ minHeight: "auto", margin: 0, padding: 0, height: 0, border: "none" }} />
              </>
            )}
            {!!sketchFiles.length && (
              <>
                <Headline gutterBottom>Sketchup:</Headline>
                {sketchFiles.map(({ resourceFile, resourceName, resourceLink, resourceType }, index) => {
                  const label = resourceFile?.localFile?.publicURL || resourceLink

                  return (
                    <File
                      key={index}
                      href={!!resourceFile ? resourceFile.localFile?.publicURL : resourceLink}
                      target={!!resourceLink ? "_blank" : undefined}
                      onClick={() =>
                        handleFileDownload({
                          label,
                          resourceType
                        })
                      }
                      rel="noopener noreferrer"
                      className={!!resourceFile ? `sketchup-link` : `warehouse-link`}
                    >
                      <SketchupIcon />
                      <ResourceName color="inherit">{resourceName}</ResourceName>
                    </File>
                  )
                })}
                <File href="https://youtu.be/dRpftRCguuA" target="_blank" rel="noopener noreferrer">
                  <PlayIcon />
                  <ResourceName color="inherit">
                    New to SketchUp?
                    <br />
                    Watch our brief intro
                  </ResourceName>
                </File>

                <File
                  href="https://youtu.be/dRpftRCguuA"
                  style={{ minHeight: "auto", margin: 0, padding: 0, height: 0, border: "none" }}
                />
                <File
                  href="https://youtu.be/dRpftRCguuA"
                  style={{ minHeight: "auto", margin: 0, padding: 0, height: 0, border: "none" }}
                />
              </>
            )}
          </Attachments>
          <NewsletterCtaCont open={newsletterCta}>
            <NewsletterModule
              headline="Thanks for downloading"
              newsletterType="cta"
              subheadline="Stay up to date with all the latest DIY tips"
            />
            <CloseButton onClick={() => setNewsletterCta(false)}>×</CloseButton>
          </NewsletterCtaCont>
        </Spacer>

        <Spacer pb={40}>
          {videoId && (
            <VideoContainer>
              <iframe title={title} src={`https://www.youtube.com/embed/` + videoId + `?autoplay=1&rel=0`} />
            </VideoContainer>
          )}
          <Textarea content={text} />
        </Spacer>

        {process.env.GATSBY_ACTIVE_LANGUAGE === "EN" && (
          <>
            <Spacer pb={40}>
              <Headline variant="h4" component="p">
                GET THE RIGHT MATERIALS FOR THIS PROJECT!
              </Headline>

              {materials ? (
                <>
                  <Excerpt gutterBottom>
                    Don’t know where to start? We got you covered! Check out the materials list below to get all the
                    pieces you need to start building this project or let us help you get a quote at a retailer near
                    you.
                  </Excerpt>
                  <ProjectMaterials materials={materials} projectId={databaseId} />
                </>
              ) : (
                <Excerpt gutterBottom>
                  Visit our <Link to="/find-a-retailer">Retailer Locator</Link> page to view a list of Authorized
                  Western Red Cedar retailers in an area near you!
                </Excerpt>
              )}

              <Headline variant="h4" component="p">
                NEED MORE OUTDOOR PROJECT IDEAS?
              </Headline>
              <Excerpt gutterBottom>
                Visit our Western Red Cedar <Link to="/outdoor/free-diy-project-plans/">Outdoor Project Plans</Link>{" "}
                page, and choose from a wide variety of free downloadable plans and videos for your DIY pleasure!
              </Excerpt>

              <Headline variant="h4" component="p">
                TOO DAUNTING OF A PROJECT TO TAKE ON YOURSELF?
              </Headline>
              <Excerpt gutterBottom>
                Visit our <Link to="/outdoor/diy-kits/">Ready-Made-Kits</Link> page to find a list of our partners who
                provide easy-to-assemble “pre-cut” cedar projects.
              </Excerpt>
            </Spacer>
            <Spacer pb={40}>
              <Headline variant="h4" component="p">
                DISCLAIMER
              </Headline>
              <Excerpt gutterBottom>
                Building plans on realceader.com could contain unintentional technical, typographical or photographic
                errors. Although reasonable efforts are made to ensure all plans are accurate and updated, The Western
                Red Cedar Lumber Association and affiliates do not warrant or make any representation concerning the
                plans accuracy.
              </Excerpt>
            </Spacer>
            <Spacer mt={30} mb={30}>
              <NewsletterFooterCont open={newsletterCta}>
                <NewsletterModule newsletterType="project" />
              </NewsletterFooterCont>
            </Spacer>
          </>
        )}
      </SidebarContent>
    </>
  )
}

const VideoContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;

  iframe {
    width: 100%;
    aspect-ratio: 16/9;
  }
`

const Attachments = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Headline = styled(Typography)`
  width: 100%;
`

const File = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.textgrey};
  margin-bottom: 20px;
  padding: 5px 10px 5px 46px;
  text-transform: uppercase;
  color: ${theme.colors.textgrey};
  min-height: 50px;
  width: 100%;
  transition: ease all 0.2s;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;

  @media (min-width: 420px) {
    width: calc(50% - 10px);
  }

  @media (min-width: 640px) {
    width: calc(33.3333% - 10px);
  }

  &:visited,
  &:active {
    color: ${theme.colors.primary};
    text-decoration: underline;
  }

  &:hover {
    color: #fff;
    background-color: ${theme.colors.textgrey};
    svg path,
    svg polygon {
      color: white;
      fill: white;
    }
  }

  svg {
    transition: ease all 0.2s;
    position: absolute;
    left: 12px;
  }
`

const ResourceName = styled(Typography)`
  font-size: 14px;
  line-height: 19px;
`

const NewsletterCtaCont = styled.div`
  border: ${(props) => (props.open ? "1px solid #727272" : "none")};
  max-height: ${(props) => (props.open ? "600px" : 0)};
  overflow: hidden;
  position: relative;
`

const CloseButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 32px;
  position: absolute;
  top: 15px;
  right: 15px;
`

const Excerpt = styled(Typography)``

const NewsletterFooterCont = styled.div`
  display: ${(props) => (props.open ? "none" : "block")};
  overflow: hidden;
  position: relative;
`

export const CollectionQuery = graphql`
  query DefaultSingleProject($id: String!) {
    wpProject(id: { eq: $id }) {
      databaseId
      title
      uri
      date
      Project {
        fieldGroupName
        youtubeUrl
        text
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
          }
        }
        resourceLinks {
          resourceLink
          resourceName
          resourceType
          resourceFile {
            localFile {
              publicURL
            }
          }
        }
        materials {
          group {
            label
            items {
              width
              thickness
              quantity
              partNumber
              nominalSizes
              material
              length
              description
            }
          }
        }
      }
    }
  }
`
